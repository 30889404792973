import React from "react"
import Image from "components/image"
import Text from "components/case-study/case-study-text-block"
import TwoColumnImageGrid from "components/case-study/case-study-two-col-image-grid"
import Testimonial from "components/case-study/case-study-testimonial"

const CaseStudyContent = ({ content }) => {
  if (!content) return null
  return (
    <div className="-mt-11">
      {content.map((block, i) => (
        <section
          key={`${block.fieldGroupName}-${i}`}
          className="case-study-section my-11"
        >
          <RenderFlexibleBlock block={block} />
        </section>
      ))}
    </div>
  )
}

export default CaseStudyContent

const RenderFlexibleBlock = ({ block }) => {
  switch (block.fieldGroupName) {
    case "CaseStudy_Casestudy_FlexibleContent_Image":
      return (
        <div className="o-container">
          <section className="md:px-7">
            <Image data={block} />
            {block.video && (
              <video
                autoPlay="true"
                playsInline
                loop
                muted
                className="ratio-16-9"
              >
                <source src={block.video.mediaItemUrl} type="video/mp4" />
              </video>
            )}
          </section>
        </div>
      )

    case "CaseStudy_Casestudy_FlexibleContent_Text":
      return <Text data={block} />
    case "CaseStudy_Casestudy_FlexibleContent_TwoColumnImageGrid":
      return <TwoColumnImageGrid data={block} />
    case "CaseStudy_Casestudy_FlexibleContent_Testimonial":
      return <Testimonial data={block} />

    default:
      return null
  }
}
