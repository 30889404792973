import React from "react"
import FadeIn from "transitions/fade-in"

const CaseStudyOverview = ({ data }) => {
  const { subheading, heading, content, aside } = data

  return (
    <section className="py-16 bg-white sm:mt-3 md:py-24 case-intro">
      <div className="container">
        <div className="case-intro__max-width">
          <header className="max-w-2xl">
            <FadeIn>
              <h1 className="mb-3 text-xl font-semibold sm:mb-4">
                {subheading}
              </h1>
              <div className="mb-8 font-serif text-40px">{heading}</div>
            </FadeIn>
          </header>

          <div className="flex-wrap justify-between -ml-12 md:flex">
            {/* Heading and text */}
            <div className="w-full pl-12 md:w-8/12">
              <div className="max-w-2xl">
                <FadeIn>
                  <div
                    className="mb-8 md:mb-0 style-links"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </FadeIn>
              </div>
            </div>

            {/* Bullets */}
            <div className="w-full pl-12 md:w-4/12 md:mt-0">
              <FadeIn>
                <div
                  className="font-serif case-intro__aside style-links c-rich-text has-line-bullets md:text-center"
                  dangerouslySetInnerHTML={{ __html: aside }}
                ></div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyOverview
