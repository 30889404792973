import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const Image = ({ data }) => {
  if (!data || !data.image) return null
  const { fluid } = (data.image.localFile || {}).childImageSharp || {}

  return (
    <section>
      {fluid ? (
        <Img fluid={fluid} alt={data.image.altText} />
      ) : (
        <img
          src={data.image && data.image.sourceUrl}
          alt={data.image.altText}
        />
      )}
    </section>
  )
}

export default Image
