import React from "react"
import { renderImage } from "utils/imageRender"

const CaseStudyHero = ({ hero }) => {
  const {
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundType,
    logo,
    backgroundColour,
    video,
  } = hero

  const hasImage = backgroundImageDesktop !== null

  return (
    <section
      className={`case-hero bg-grey mt-18 sm:mt-26 md:mt-36 flex flex-col case-hero--${backgroundType} ${video &&
        `video-hero`} ${
        hasImage ? "case-hero--has-image" : "case-hero--no-image"
      }`}
      style={{ backgroundColor: backgroundColour }}
    >
      {!video && (
        <div className=" flex flex-col items-center flex-auto">
          {backgroundType !== "cover-image" && logo && (
            <div className="case-hero__logo px-5 flex flex-col flex-auto justify-center">
              {renderImage(logo)}
            </div>
          )}

          <div className="w-full case-hero__image">
            {renderImage(backgroundImageDesktop)}
          </div>
        </div>
      )}

      {video && (
        <video autoPlay="true" playsInline loop muted className="ratio-16-9">
          <source src={video.mediaItemUrl} type="video/mp4" />
        </video>
      )}
    </section>
  )
}

export default CaseStudyHero
