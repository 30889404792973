import React from "react"
import FadeIn from "transitions/fade-in"

const Text = ({ data }) => {
  return (
    <section className="my-20 centered-text lg:mt-48 lg:mb-40 md:mt-40 md:mb-36 sm:mt-28 sm:mb-28">
      <div className="o-container">
        <div className="md:px-7">
          {data.overline && (
            <FadeIn>
              <h2
                className="text-sm font-semibold uppercase"
                dangerouslySetInnerHTML={{ __html: data.overline }}
              ></h2>
            </FadeIn>
          )}
          {data.heading && (
            <div className="mt-6 mb-8 font-serif text-38px">
              <FadeIn>
                <span dangerouslySetInnerHTML={{ __html: data.heading }}></span>
              </FadeIn>
            </div>
          )}
          {data.content && (
            <div className="text-lg font-medium leading-relaxed">
              <FadeIn>
                <span dangerouslySetInnerHTML={{ __html: data.content }}></span>
              </FadeIn>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Text
