import React from "react"
import { renderImage } from "utils/imageRender"

const CaseStudyTwoColumnImageGrid = ({ data }) => {
  const { left, right, settings } = data
  let { leftColumnWidth, rightColumnWidth } = settings

  return (
    <section className="o-container case-study-two-col-images">
      <div className="lg:px-7">
        <div className="flex flex-wrap -ml-11 ">
          {/* Left */}
          <div className={`md:w-${leftColumnWidth}/12 w-full pl-11`}>
            <div className="grid md:row-gap-11 md:h-full">
              {left &&
                left
                  .filter(({ image }) => image)
                  .map(({ image }, i) => {
                    return (
                      image &&
                      image.databaseId &&
                      image.localFile && image.localFile.childImageSharp && (
                        <div key={`two-col-img-${image.databaseId}`}>
                          {renderImage(image)}
                        </div>
                      )
                    )
                  })}

              {left &&
                left
                  .filter(({ video }) => video)
                  .map(({ video }, i) => (
                    <video
                      autoplay="true"
                      playsInline
                      loop
                      muted
                      className="h-72 h-auto md:h-auto w-full object-cover"
                    >
                      <source src={video.mediaItemUrl} type="video/mp4" />
                    </video>
                  ))}
            </div>
          </div>

          {/* Right */}
          <div className={`md:w-${rightColumnWidth}/12 w-full pl-11`}>
            <div className="grid md:row-gap-11 md:h-full">
              {right &&
                right
                  .filter(({ image }) => image)
                  .map(({ image }, i) => {
                    return image &&
                      image.databaseId &&
                      image.localFile &&  image.localFile.childImageSharp &&  (<div
                      key={`two-col-img-${image.databaseId}`}
                      className="mt-11 md:mt-0"
                    >
                      {renderImage(image)}
                    </div>)
                  })}

              {right &&
                right
                  .filter(({ video }) => video)
                  .map(({ video }, i) => (
                    <video
                      autoplay="true"
                      playsInline
                      loop
                      muted
                      className="h-72 h-auto md:h-auto w-full object-cover"
                    >
                      <source src={video.mediaItemUrl} type="video/mp4" />
                    </video>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyTwoColumnImageGrid
