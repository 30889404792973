import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import CaseStudyHero from "components/case-study/case-study-hero"
import { ParallaxProvider } from "react-scroll-parallax"
import CaseStudyIntro from "components/case-study/case-study-intro"
import CaseStudyContent from "components/case-study/case-study-content"
import ExploreOurWork from "components/about/explore-our-work"
import handleExternalUrls from "utils/handleExternalUrls"

const CaseStudy = React.memo(({ data: { wpCaseStudy, wpPage, wp } }) => {
  if (!wpCaseStudy) return null

  useEffect(() => {
    handleExternalUrls()
  }, [wpCaseStudy]) // Only re-run if wpCaseStudy changes

  const { seo, title, content, databaseId } = wpCaseStudy
  const { hero, intro, flexibleContent } = content

  const relatedWork = {
    ...wp.options.options.caseStudy.relatedWork,
    caseStudies: wpPage.work.caseStudyCards.filter(
      card => card.databaseId !== databaseId
    ),
  }

  const colour = hero.textColour === "black" ? "midnight" : "white"

  return (
    <ParallaxProvider>
      <Transition>
        <SEO
          seo={seo}
          bodyAttributes={{
            class: `page--case-study`,
          }}
        />

        <CaseStudyHero title={title} hero={hero} video={hero.video} />

        <CaseStudyIntro data={intro} />
        <CaseStudyContent content={flexibleContent} />
        <ExploreOurWork data={relatedWork} />
      </Transition>
    </ParallaxProvider>
  )
})

export const query = graphql`
  query CaseStudy($id: Int!) {
    wpCaseStudy(databaseId: { eq: $id }) {
      ...CaseStudySEO
      databaseId
      title
      content: caseStudy {
        hero {
          backgroundType
          textColour
          backgroundColour
          backgroundImageMobile {
            ...FluidMobileImage
          }
          backgroundImageDesktop {
            ...FluidFullWidthImage
          }
          logo {
            ...FluidFullWidthImage
          }
          video {
            mediaItemUrl
          }
        }

        intro {
          content
          heading
          subheading
          aside
        }

        flexibleContent {
          ... on WpCaseStudy_Casestudy_FlexibleContent_Image {
            fieldGroupName
            image {
              ...FluidHalfWidthImage
            }
            video {
              mediaItemUrl
            }
          }
          ... on WpCaseStudy_Casestudy_FlexibleContent_Text {
            content
            fieldGroupName
            heading
            overline
          }
          ... on WpCaseStudy_Casestudy_FlexibleContent_Testimonial {
            testimonialQuote
            fieldGroupName
            testimonialName
            testimonialPosition
          }

          # Two column images
          ... on WpCaseStudy_Casestudy_FlexibleContent_TwoColumnImageGrid {
            fieldGroupName
            settings {
              leftColumnWidth
              rightColumnWidth
            }
            left {
              image {
                ...FluidHalfWidthImage
              }
              video {
                mediaItemUrl
              }
            }
            right {
              image {
                ...FluidHalfWidthImage
              }
              video {
                mediaItemUrl
              }
            }
          }
        }
      }
    }

    wpPage(databaseId: { eq: 3835 }) {
      work {
        caseStudyCards {
          ...CaseStudyCard
        }
      }
    }
    wp {
      options {
        options {
          caseStudy {
            relatedWork {
              content
              heading
            }
          }
        }
      }
    }
  }
`

export default CaseStudy
