import React from "react"
import FadeIn from "transitions/fade-in"

const Testimonial = ({ data }) => {
  const { testimonialName, testimonialPosition, testimonialQuote } = data
  return (
    <section className="my-20 centered-text lg:mt-48 lg:mb-40 md:mt-40 md:mb-36 sm:mt-28 sm:mb-28">
      <div className="o-container">
        <div className="max-w-xl mx-auto text-center md:text-left">
          <FadeIn>
            <div className="-my-8 font-serif text-67px">“</div>
            <div
              className="font-serif text-2xl leading-snug md:text-3xl mb-14"
              dangerouslySetInnerHTML={{ __html: testimonialQuote }}
            ></div>
            <div className="font-semibold text-17px">{testimonialName}</div>
            <div className="text-sm font-semibold tracking-wider uppercase text-iron">
              {testimonialPosition}
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
